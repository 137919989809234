<template>
  <page-container title="个人中心" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <a-form-model ref="userForm" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:14}">
            <a-form-model-item label="用户名称" prop="username">
              <a-input v-model.trim="formData.username" />
            </a-form-model-item>
          <a-form-model-item label="旧密码" prop="oldpassword">
            <a-input type="password" v-model.trim="formData.oldpassword" />
          </a-form-model-item>
            <a-form-model-item label="新密码" prop="password">
              <a-input type="password" v-model.trim="formData.password" />
            </a-form-model-item>
            <a-form-model-item label="确认新密码" prop="passwordConfirm">
              <a-input type="password" v-model.trim="formData.passwordConfirm" />
            </a-form-model-item>
            <a-form-model-item label="手机号" prop="phonenum">
              <a-input v-model.trim="formData.phonenum" />
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
              <a-button size="large" type="primary" @click="save">保存</a-button>
              <a-button size="large" @click="reset" style="margin-left: 10px;">重置</a-button>
            </a-form-model-item>
          </a-form-model>
      </div>
    </div>
  </page-container>
</template>

<script>
import { modifyPersonInfo } from 'A/xtpz.js'
import {getCache, getItemFromArrayByKey} from 'U'
import store from '@/store'
import { mapState } from 'vuex'

export default {
  name: 'userCenter',
  data() {
    return {
      breadcrumb: [
        {
          name: '系统配置',
          path: ''
        },
        {
          name: '个人中心',
          path: ''
        },
      ],
      formData: {
        userid: '',
        username: '',
        password: '',
        passwordConfirm: '',
        oldpassword: '',
        phonenum: '',
        usertype:null,
        userdepid:null,
        playtype:null,
      },
      formRules: {
        username: [{required: true, message: '请输入账户'}],
        passwordConfirm: [
          {
            validator: (rule, value, callback) => {
              if(value == this.formData.password) {
                callback();
              }else {
                callback('两次输入的密码不一致')
              }
            }, trigger: 'blur'
          }
        ],
        oldpassword: [
          {
            validator: (rule, value, callback) => {
              if(!value && this.formData.password) {
                callback('请输入旧密码');
              }else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        phonenum: [
          {
            validator: (rule, value, callback) => {
              if(!value || /^1\d{10}$/.test(value)) {
                callback();
              }else {
                callback('请输入正确的手机号码')
              }
            }, trigger: 'blur'
          }
        ],
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
    let logininfo = getCache('logininfo', true);
    if (logininfo) {
      console.log(logininfo)
      this.formData.usertype=logininfo.usertype
      this.formData.userdepid=logininfo.userdepid
      this.formData.playtype=logininfo.playtype
    }
  },
  methods: {
    init() {
      this.reset();
    },
    save() {
      this.$refs.userForm.validate(valid => {
        if(valid) {
          this.updateUserInfo();
        }else {
          return false;
        }
      })
    },
    updateUserInfo() {
      this.showLoading();
      let params = {...this.formData};
      modifyPersonInfo(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          this.$store.commit('setUserInfo', {
            ...this.userInfo,
            usertype: this.formData.usertype,
            userdepid: this.formData.userdepid,
            playtype: this.formData.playtype,
            username: this.formData.username,
            phonenum: this.formData.phonenum
          })
        }else {
          this.$message.error(res.errormsg||'操作失败')
        }
      })
    },
    reset() {
      if(this.$refs.userForm) {
        this.$refs.userForm.clearValidate();
      }
      this.formData.userid = this.userInfo.userid;
      this.formData.username = this.userInfo.username;
      this.formData.phonenum = this.userInfo.phonenum;
      this.formData.password = '';
      this.formData.passwordConfirm = '';
      this.formData.oldpassword = '';
    }
  }
}
</script>
